export default {
  address: "台南市東區中華東路一段223號",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.926008491437!2d120.23169351496725!3d22.989748184969727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e76b95553cf57%3A0x62a3c6ea3b222676!2zNzAx5Y-w5Y2X5biC5p2x5Y2A5Lit6I-v5p2x6Lev5LiA5q61MjIz6Jmf!5e0!3m2!1szh-TW!2stw!4v1629698467746!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/6rSqEMzDiPw8fnqd8",
  phone: "06-2090888",
  fbLink:
    "https://www.facebook.com/qingjinglinbarclay",
  fbMessage: "https://m.me/qingjinglinbarclay/",
  caseName: "巴克禮PARK2",
  indigatorLength: 10,
  houseInfos: [
    ["投資興建", "清景麟資產有限公司"], 
    ["格局規劃", "二~三房"],
    ["接待中心", "台南市東區中華東路一段223號"],
    ["企劃銷售", "清揚國際實業股份有限公司"],
    ["行銷企劃", "<img src='"+ require('@/assets/img/houseinfo-sales.png')+"'>"],
  ],

  gtmCode: ["MDTD84M"], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
    title: "立即預約",
    subTitle: "若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！"
  }
};
