export default [
  // {
  //   name: "幸福建築",
  //   iconSrc: require("@/assets/img/nav/1.png"),
  //   imgSrc: "",
  //   subTitle: "",
  //   section: "section2",
  //   svgSrc: "",
  //   offset: -50,
  //   mobileOffset: 0
  // },
  // {
  //   name: "翻轉基隆",
  //   iconSrc: require("@/assets/img/nav/2.png"),
  //   imgSrc: "",
  //   subTitle: "",
  //   section: "section3",
  //   svgSrc: "",
  //   offset: -50,
  //   mobileOffset: 0
  // },
  // {
  //   name: "生活機能",
  //   iconSrc: require("@/assets/img/nav/3.png"),
  //   imgSrc: "",
  //   subTitle: "",
  //   section: "nav-section-1",
  //   svgSrc: "",
  //   offset: -50,
  //   mobileOffset: 0
  // },
  // {
  //   name: "精品建材",
  //   iconSrc: require("@/assets/img/nav/4.png"),
  //   imgSrc: "",
  //   subTitle: "",
  //   section: "nav-section-2",
  //   svgSrc: "",
  //   offset: -50,
  //   mobileOffset: 0
  // }
];
